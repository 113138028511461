.App {
  text-align: center;
  font-family: Benton Sans;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.NotYetDeveloped {
  padding: 2rem;
  text-align: center;
}

.mapwrap {
  background: none;
  overflow: hidden;
}
.counties {
  fill: #efefef;
}

.filled-territory,
.unfilled-territory {
  cursor: pointer;
}

.filled-territory {
  fill: #1876d1;
  opacity: 0.6;
  stroke: #fff;
  stroke-width: 1px;
}
.filled-territory:hover {
  fill: #063b6f;
}

.zoomed-county {
  fill: #1876d1;
  stroke: #fff;
  stroke-width: 1px;
}

.zoomed-county-hover:hover {
  stroke-width: 4px;
  fill: lightseagreen;
}

.zoomed-county-selected {
  fill: red;
}

.zoomed-county-hover-sim {
  stroke-width: 4px;
  fill: lightseagreen;
}

.unfilled-territory {
  fill: #6fbf73;
  opacity: 0.6;
  stroke: #fff;
  stroke-width: 1px;
}
.unfilled-territory:hover {
  fill: #357a38;
}


.onboarding-territory {
  fill: #f7fb3a;
  opacity: 0.6;
  stroke: #fff;
  stroke-width: 1px;
}
.onboarding-territory:hover {
  fill: #cbce02;
}


.training-territory {
  fill: #ee9b2e;
  opacity: 0.6;
  stroke: #fff;
  stroke-width: 1px;
}
.training-territory:hover {
  fill: #c27817;
}


.countyborders {
  fill: none;
  stroke: #ccc;
  stroke-width: 0.3px;
}

.countyInfoList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.tooltip {
  color: #222;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 2px 0px #a6a6a6;
  padding: 0.2em;
  text-shadow: #f5f5f5 0 1px 0;
  opacity: 0.9;
  position: absolute;
}

.small_tooltip {
  z-index: 1000000;
}
